import React from "react";
import {
  siFacebook,
  siInstagram,
  siLinkedin,
  siX,
  siCheckmarx,
  siC,
} from "simple-icons";

export const FacebookIcon: React.FC<{ size?: number; fill?: string }> = ({
  size = 24,
  fill = "#004838",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={fill}
  >
    <path d={siFacebook.path} />
  </svg>
);

export const InstagramIcon: React.FC<{ size?: number; fill?: string }> = ({
  size = 24,
  fill = "#004838",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={fill}
  >
    <path d={siInstagram.path} />
  </svg>
);

export const LinkedInIcon: React.FC<{ size?: number; fill?: string }> = ({
  size = 24,
  fill = "#004838",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={fill}
  >
    <path d={siLinkedin.path} />
  </svg>
);

export const XIcon: React.FC<{ size?: number; fill?: string }> = ({
  size = 24,
  fill = "#004838",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill={fill}
  >
    <path d={siX.path} />
  </svg>
);
