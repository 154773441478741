import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import logoImage from "../../assets/img/logoWhite.png";
import hamburgerIcon from "./assets/img/hamburger.svg";
import WaitlistForm from "../WaitlistForm/WaitlistForm"; // Adjust path as necessary
import Modal from "../common/Modal";

const Navbar: React.FC = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleScroll = () => {
    setIsSticky(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <nav className={`navbar ${isSticky ? "sticky" : ""}`}>
        <div className="logo">
          <img src={logoImage} alt="Expenses graph" className="logoImage" />
          <span className="logo-text">Hirelyst</span>
        </div>

        <div className="nav-actions">
          <ul className="nav-links">
            <li>
              <a href="#about">About</a>
            </li>
          </ul>
          <a href="#contact" className="contact-link">
            Contact
          </a>
          <button onClick={openModal} className="button">
            Book a Demo
          </button>
        </div>
        <button className="hamburger">
          <img
            src={hamburgerIcon}
            style={{ width: "2rem", height: "2rem" }}
            alt="menu"
          />
        </button>
      </nav>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <WaitlistForm />
      </Modal>
    </>
  );
};

export default Navbar;
