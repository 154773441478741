import React, { useState, useEffect } from "react";
import "./WaitlistForm.scss";
import userIcon from "./assets/img/userIcon.png";
import { ENV } from "../../env";

const WaitlistForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [userCount, setUserCount] = useState<number | null>(null);

  // Function to fetch the number of registered users
  const fetchUserCount = async () => {
    try {
      const response = await fetch(
        ENV.SHEET_API_URL, // Your GET API endpoint
        {
          method: "GET",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch user data");
      const data = await response.json();
      setUserCount(data.length); // Count the number of users
    } catch (error: any) {
      console.error("Error fetching user data:", error);
      setUserCount(null); // Handle any error by setting user count to null or handle accordingly
    }
  };

  useEffect(() => {
    fetchUserCount(); // Fetch user count when component mounts
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      Email: email,
      Created_at: new Date().toISOString(),
    };
    try {
      const response = await fetch(ENV.SHEET_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) throw new Error("Failed to submit email");
      alert("Email submitted successfully!");
      setEmail("");
      fetchUserCount(); // Fetch the updated user count after successful form submission
    } catch (error: any) {
      console.error("Failed to submit form:", error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="waitlist-container">
      <div className="header">
        <p className="launching-date">LAUNCHING NOV 24TH, 2024</p>
        <h1>Ready to hire and assess cybersecurity experts?</h1>
        <p>
          Publish your own test in minutes to start capturing email addresses
          from potential customers and promoting your launch online.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="waitlist-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="name@domain.com"
          required
        />
        <button type="submit">Join now</button>
      </form>
      <div className="footer">
        <img src={userIcon} style={{ width: "5rem" }} alt="User Icons" />
        <span>
          {userCount !== null
            ? `${userCount} users have already joined`
            : "Loading users..."}
        </span>
      </div>
    </div>
  );
};

export default WaitlistForm;
